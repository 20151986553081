// import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Accordion from '../accordion';

export default function HistoricalDocuments({ data, annualReports }) {

    return (
        <>
            <h1 className="historicalDocument__title" align="center">Reports</h1>
            <div className="accordion-section">
                <Accordion title="2025 Fact Sheets" content={ data["2025"].reverse() } type="factsheet" />
                <Accordion title="2024 Fact Sheets" content={ data["2024"].reverse() } type="factsheet" />
                <Accordion title="2023 Fact Sheets" content={ data["2023"].reverse() } type="factsheet" />
                <Accordion title="2022 Fact Sheets" content={ data["2022"].reverse() } type="factsheet" />
                <Accordion title="Annual Reports" content={ annualReports } type="annual_report"/>
            </div>
        </>
    )
}
