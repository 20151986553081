import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toaster } from "evergreen-ui";

const ApplicationForm = ({ jobTitle }) => {
  // const FILE_SIZE = 160 * 1024;
  const SUPPORTED_FORMATS = ["application/pdf"];

  const formats = SUPPORTED_FORMATS.join(",");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      attachment: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      attachment: Yup.mixed()
        .required("A file is required")
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
      // .test(
      //   "fileSize",
      //   "File too large",
      //   value => value && value.size <= FILE_SIZE
      // )
    }),
    onSubmit: (values) => submitForm(values),
  });

  const handleFileChange = async (file) => {
    formik.setFieldValue("attachment", file);
  };

  const toBase_64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }
    });

  const submitForm = async (values) => {
    const file = await toBase_64(values.attachment);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("attachment", file);
    formData.append("jobTitle", jobTitle);

    try {
      await axios({
        method: "post",
        url: `/.netlify/functions/job-applications`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toaster.success("Your application has been submitted.");
    } catch (e) {
      toaster.danger("An error occurred. Please try again.");
    }
  };

  return (
    <div className="application-form__wrapper">
      <form className="application-form" onSubmit={formik.handleSubmit}>
        <div className="form__input__wrapper">
          <label className="form__label" htmlFor="name">
            Name
          </label>

          <input
            className="form__input"
            id="name"
            name="name"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="form__input__error">{formik.errors.name}</div>
          ) : null}
        </div>

        <div className="form__input__wrapper">
          <label className="form__label" htmlFor="email">
            Email Address
          </label>
          <input
            className="form__input"
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="form__input__error">{formik.errors.email}</div>
          ) : null}
        </div>

        <div className="form__input__wrapper">
          <label className="form__label" htmlFor="attachment">
            Upload CV
          </label>
          <input
            className="form__input"
            id="attachment"
            name="attachment"
            type="file"
            accept={formats}
            onChange={({ currentTarget: { files } }) =>
              handleFileChange(files[0])
            }
            onBlur={formik.handleBlur}
          />
          {formik.touched.attachment && formik.errors.attachment ? (
            <div className="form__input__error">{formik.errors.attachment}</div>
          ) : null}
        </div>

        <button
          disabled={formik.isSubmitting || !formik.isValid}
          className="form__submit"
          type="submit"
        >
          {formik.isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ApplicationForm;
